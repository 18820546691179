<template>
  <Header />
  <div class="qr-code__container">
    <QrcodeStream @detect="onDetect"/>
  </div>
</template>
<script setup>
import Header from "@components/general/Header.vue";
import { QrcodeStream } from 'vue-qrcode-reader'

function onDetect(codes) {
  window.location.href = codes[0].rawValue
}
</script>

<style>
.qr-code__container {
  margin: 3rem;
  padding: 8px;
  height: calc(60vh - 50px);

  background:
      linear-gradient(to right, green 8px, transparent 8px) 0 0,
      linear-gradient(to right, green 8px, transparent 8px) 0 100%,
      linear-gradient(to left, green 8px, transparent 8px) 100% 0,
      linear-gradient(to left, green 8px, transparent 8px) 100% 100%,
      linear-gradient(to bottom, green 8px, transparent 8px) 0 0,
      linear-gradient(to bottom, green 8px, transparent 8px) 100% 0,
      linear-gradient(to top, green 8px, transparent 8px) 0 100%,
      linear-gradient(to top, green 8px, transparent 8px) 100% 100%;

  background-repeat: no-repeat;
  background-size: 20px 20px;
}
</style>