import App from '@views/login/App.vue'
import { generateApplication } from '@helpers/generate_component.js';
import { generateAxiosInstance } from '@helpers/axios_provider.js'

import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

generateApplication(
    App,
    "#app",
    {
        injections: [{ name: '$axios', module: generateAxiosInstance('/api/v1/') }],
        packages: [VueSweetalert2, autoAnimatePlugin]
    }
)